import { CircularProgress, Autocomplete, TextField, FormLabel } from "@mui/material";

const AutocompleteSingleSelect = ({
  id,
  label,
  idKey,
  value,
  setValue,
  labelKey,
  noOptionsText,
  placeholder,
  isLoading,
  required,
  options = [],
}) => {
  const values = options.map((o) => o[idKey]);
  return (
    <div className="d-flex flex-column align-items-start gap-1">
      <FormLabel htmlFor={id} className="text-primary fw-semibold" required={Boolean(required)}>{label}</FormLabel>
      <Autocomplete
        id={id}
        fullWidth
        disabled={isLoading}
        sx={{
          "& .MuiOutlinedInput-root": {
            padding: "14px 20px",
            border: "1px solid #23145917",
            borderRadius: "12px",
            color: "#1f2124",
            minHeight: 59,
            outline: "none",
            boxShadow: "none !important",
            "&.Mui-focused": {
              borderColor: "#69727d",
            },
            bgcolor: isLoading ? "#E5EBED !important" : "#FFF",
            "& .MuiOutlinedInput-notchedOutline": {
              border: "none !important",
            },
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
              border: "none !important",
              boxShadow: "none !important",
            },
            "&> .MuiOutlinedInput-input": {
              border: "0 !important",
              p: 0,
              fontWeight: "500 !important",
              "&+ .MuiCircularProgress-root": {
                position: "absolute",
                top: "19.5px",
                right: "35px",
              },
            },
          },
        }}
        noOptionsText={noOptionsText}
        value={value || null}
        onChange={(_, newValue) => {
          setValue(newValue ? newValue : "");
        }}
        options={values}
        getOptionLabel={(option) => {
          const op = options.find((o) => o[idKey] === option);
          const opt = op?.[labelKey] === undefined ? "" : op?.[labelKey];
          return !isLoading ? opt : "";
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder={placeholder}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {isLoading ? <CircularProgress color="info" size={18} /> : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
      />
    </div>
  );
};

export default AutocompleteSingleSelect;
