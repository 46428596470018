
import { useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import logo from "../../assets/images/StaffWay-logo.png";
import logo_webp from "../../assets/images/StaffWay-logo.webp";
import Picture from "../../components/picture";
import { DeskNav, MainNav, MobileNav, ToggleBtn } from "./style";
import { navLinks } from "./mainNavData";
import { BtnSecondary } from "../../components/button";
import useMediaQuery from "../../hooks/useMediaQuery";
import breakpoints from "../../helper/breakpoints";
import { Dialog } from "@material-ui/core";
import { Box } from "@mui/material";
import { JobFilterForm } from "../../layout/job-list/components/JobFilters";

const Navbar = () => {
  const showToggler = useMediaQuery(breakpoints.down("lg"));
  const hideSearchBtn = useMediaQuery(breakpoints.down("sm"));
  const [open, setOpen] = useState(false);
  const [showMobileNav, setShowMobileNav] = useState(false);

  useEffect(() => {
    if (!showToggler) {
      setShowMobileNav(false);
    }
  }, [showToggler]);

  useEffect(() => {
    if (!hideSearchBtn) {
      setOpen(false);
    }
  }, [hideSearchBtn]);

  const handleShowNav = () => setShowMobileNav(prev => !prev);
  return (
    <div className="bg-light position-relative">
      <div className="container-xxl">
        <MainNav className="d-flex align-items-center">
          <Link className="logo" to="/">
            <Picture src={{ webp: logo_webp, png: logo }} alt="StaffWay" width="210" />
          </Link>
          {!showToggler && (
            <DeskNav className="d-flex align-items-center justify-content-end flex-grow-1">
              {navLinks.map(({ id, link, name }) => (
                <div key={id} className="nav-item">
                  <NavLink className={({ isActive }) => isActive ? "nav-link current" : "nav-link"} to={link}>{name}</NavLink>
                </div>
              ))}
            </DeskNav>
          )}
          {!hideSearchBtn && (
            <div className="search-job d-flex justify-content-end">
              <BtnSecondary onClick={() => setOpen(true)} $sm>Search for Jobs</BtnSecondary>
              <Dialog open={open} onClose={() => setOpen(false)} fullWidth PaperProps={{
                style: { overflow: "unset", position: "relative" }
              }}>
                <Box onClick={() => setOpen(false)} top={-11} right={-11} width={22} height={23} className="d-flex align-items-center justify-content-center position-absolute rounded-circle bg-primary" sx={{ cursor: "pointer" }}>
                  <svg xmlns="http://www.w3.org/2000/svg" height=".8em" fill="#fff" viewBox="0 0 384 512">
                    <path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" />
                  </svg>
                </Box>
                <Box px={2} py={4} sx={{ overflowY: "overlay" }}>
                  <JobFilterForm onClose={() => setOpen(false)} />
                </Box>
              </Dialog>
            </div>
          )}
          {showToggler && (
            <ToggleBtn className="ms-auto" onClick={handleShowNav}>
              {showMobileNav ?
                (<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" viewBox="-2 -2 20 20">
                  <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
                </svg>) :
                (<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" viewBox="0 0 16 16">
                  <path fillRule="evenodd" d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z" />
                </svg>)}
            </ToggleBtn>
          )}
        </MainNav>
      </div>
      {showToggler && (
        <MobileNav className={`d-flex flex-column w-100 position-absolute ${showMobileNav ? "active" : ""}`}>
          {navLinks.map(({ id, link, name }) => (
            <div key={id} className="nav-item">
              <NavLink className={({ isActive }) => isActive ? "nav-link current" : "nav-link"} to={link} onClick={handleShowNav}>{name}</NavLink>
            </div>
          ))}
        </MobileNav>
      )}
    </div>
  )
}

export default Navbar