import styled from "styled-components";
import breakpoints from "../../../helper/breakpoints";
import object_1 from "../../../assets/images/element-mask.png";
import object_2 from "../../../assets/images/element-mask-dot-2.png";
import object_3 from "../../../assets/images/element-mask-2.png";
import bg_cta from "../../../assets/images/bg-cta.png";

export const H3Title = styled.h3`
font-size: 2.1rem;
font-weight: 600;
line-height: 1.4em;
${breakpoints.down("xl")} {
  font-size: 2rem;
}
${breakpoints.down("md")} {
  font-size: 1.6rem;
}
`;

export const HeaderSection = styled.header`
padding: 85px 0;
.content-box {
  padding: 50px 116px 0 0;
  .title {
    font-size: 4.209rem;
  }
  .text-text{
    font-size: 1.25rem;
    line-height: 1.6em;
    ${breakpoints.down("sm")} {
      &+button{
        width: 100%;
      }
    }
  }
}
.hero-images > div {
  position: relative;
  margin-bottom: 20px;
  z-index: 0;
}
.hero-images {
  &> .hero-img {
    z-index: 1;
    img {
      width: 88%;
      height: auto;
      border: 10px solid var(--bs-primary);
      border-radius: 35px;
      ${breakpoints.down("xl")} {
        width: 80%;
      }
      ${breakpoints.down("md")} {
        width: 100%;
        border: 8px solid var(--bs-primary);
        border-radius: 28px;
      }
    }
  }
}
  

.hero-images > .mask-1 {
  -webkit-mask-image: url(${object_1});
  -webkit-mask-size: contain;
  -webkit-mask-position: top right;
  -webkit-mask-repeat: no-repeat;
  background-color: transparent;
  background-image: linear-gradient(5.42rad, var(--bs-success-dark) 0%, var(--bs-success) 100%);
  border-style: none;
  margin: 0em 0em -14em 0em;
  height: 250px;
}

.hero-images > .mask-2 {
  -webkit-mask-image: url(${object_2});
  -webkit-mask-size: contain;
  -webkit-mask-position: bottom left;
  -webkit-mask-repeat: no-repeat;
  background-color: var(--bs-warning);
  border-style: none;
  margin: -10em 0em 0em 0em;
  height: 250px;
  transform: translateX(-105px);
  z-index: 0;
}

.hero-images > .obj-1 {
  text-align: left;
  z-index: 2;
  margin: -19em 0em 0em 2em;
  ${breakpoints.down("md")} {
    display: none;
  }
}

.hero-images > .obj-2 {
  text-align: right;
  z-index: 2;
  margin: -12em -7em 0em 0em;
  transform: translateY(-145px);
}

.trusted-heading-title{
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 1.6em;
  ${breakpoints.down("xxl")} {
    font-size: 1.4rem;
  }
  ${breakpoints.down("lg")} {
    font-size: 1.25rem;
  }
}

${breakpoints.down("xl")} {
  padding: 102px 0 68px;
  .content-box {
    padding: 0 0 68px 0;
    .title {
      font-size: 3.125rem;
    }
    .text-text{
      font-size: 1.125rem;
    }
  }
  .hero-images > .mask-1 {
    margin: 0em 0em -14em 0em;
  }

  .hero-images > .mask-2 {
    margin: -10em 0em 0em 0em;
    transform: translateX(-105px);
  }

  .hero-images > .obj-1 {
    margin: -19em 0em 0em 6em;
  }

  .hero-images > .obj-2 {
    margin: -12em -2em 0em 0em;
    transform: translateY(-145px);
  }
}
${breakpoints.down("lg")} {
  .hero-images > .obj-1 {
    margin: -19em 0em 0em 2em;
  }
}
${breakpoints.down("md")} {
  padding: 80px 0 64px;
  .content-box {
    padding: 0 0 64px 0;
    .title {
      font-size: 2.1rem;
    }
  }
  .hero-images > .mask-1 {
    margin: 0em -1em -6em 0em;
    height: 115px;
  }

  .hero-images > .mask-2 {
    margin: -8em 0em 0em 0em;
    height: 151px;
    transform: translateX(-54px);
  }

  .hero-images > .obj-2 {
    margin: -2em -4em -4em 0em;
    &>img {
      width: 50%;
      height: auto;
    }
  }
}

.spacer {
  padding: 50px 0;
  ${breakpoints.down("xl")} {
    padding: 40px 0;
  }
  ${breakpoints.down("md")} {
    padding: 30px 0;
  }
  &> div{
    border-top: 1px solid #23145917;
  }
}
`;

export const FeatureSection = styled.section`
padding: 7em 0;
${breakpoints.down("lg")} {
  padding: 6em 0;
}
${breakpoints.down("md")} {
  padding: 4em 0;
}
.card {
  padding: 1.2rem;
  border-radius: 24px;
  transition: background .3s,border .3s,border-radius .3s,box-shadow .3s,transform .4s;
  &:hover {
    box-shadow: 0px 16px 30px 0px rgba(35.00000000000012, 20.000000000000004, 88.99999999999997, 0.15);
  }
  &> img {
    width: 30%;
    ${breakpoints.down("lg")} {
      width: 40%;
    }
    ${breakpoints.down("md")} {
      width: 30%;
    }
  }
  &> h5 {
    font-size: 1.25rem;
    font-weight: 600;
  }
  &> p {
    font-size: 17px;
    line-height: 1.6em;
    ${breakpoints.down("md")} {
      font-size: 16px;
    }
  }
}
`;

export const HomeJobListingSection = styled.section`
padding: 7em 0;
${breakpoints.down("lg")} {
  padding: 5em 0;
}
`;

export const GetStartedSection = styled.section`
padding: 8em 0 14em ;
background-color: transparent;
overflow: hidden;
background-image: linear-gradient(180deg, var(--bs-secondary) 0%, #F4F7F6 100%);
${breakpoints.down("xl")} {
  padding: 6em 0 12em;
}
${breakpoints.down("md")} {
  padding: 5em 0 12em;
}

.obj {
  -webkit-mask-image: url(${object_3});
  -webkit-mask-size: contain;
  -webkit-mask-position: top left;
  -webkit-mask-repeat: no-repeat;
  background-color: transparent;
  background-image: linear-gradient(185deg, var(--bs-warning) 0%, var(--bs-warning-dark) 100%);
  border-style: none;
  margin: 0 0 -15em -2em;
  height: 270px;
  z-index: 0;
  ${breakpoints.down("xl")} {
    margin: 0 0 -15em 0;
  }
  ${breakpoints.down("sm")} {
    height: 145px;
    margin: 0 0 -9em -1em;
  }
}
.image {
  img {
    ${breakpoints.down("xl")} {
      width: 90%;
    }
    ${breakpoints.down("sm")} {
      width: 100%;
    }
  }
}
`;

export const HomeCounterSection = styled.section`
margin-top: -7em;
.populated {
  position: relative;
  border-radius: 24px;
  padding: 3em;
  background-color: transparent;
  background-image: linear-gradient(150deg, var(--bs-accent) 0%, var(--bs-info) 100%);
  ${breakpoints.down("lg")} {
    border-radius: 20px;
    padding: 3em 1.5em;
  }
  ${breakpoints.down("md")} {
    border-radius: 20px;
    padding: 4em 1.2em 3em;
  }
  &>.overlay {
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    position: absolute;
    background-image: url(${bg_cta});
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    z-index: 0;
    opacity: 0.75;
  }
}
${breakpoints.down("sm")} {
  .link {
    width: 100%;
  }
}
`;

export const HomeFaqsBox = styled.section`
margin: -14.1em 0 0;
padding: 20em 0 7em;
background-color: transparent;
background-image: linear-gradient(0deg, var(--bs-secondary) 0%, #F4F7F6 100%);

${breakpoints.down("md")} {
  padding: 18em 0 5em;
}
`;