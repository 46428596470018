import { useCallback, useEffect, useState } from "react";
import { InputField } from '../../../common/inputs';
import { JobFilterDrawerUi, JobFilterFormUi } from "./style";
import { Box, Button, Grid } from "@mui/material";
import { useFormik } from "formik";
import AutocompleteSingleSelect from "../../../common/inputs/AutocompleteSingleSelect";
import { useHandleGetApiQuery } from "../../../app/features/apis/CommonGetApi";
import { BtnLight, BtnPrimary } from "../../../components/button";
import useNavigateSearch from "../../../hooks/useNavigateSearch";
import { gatTagName, getNewProperties, setFeildBySearchParams } from "../../../helper";
import useGetSearchQueryParams from "../../../hooks/useGetSearchQueryParams";
import { useLocation } from "react-router-dom";

export const JobFilterForm = ({ onClose }) => {
  const { pathname } = useLocation();
  const navigate = useNavigateSearch();
  const getParams = useGetSearchQueryParams();

  const { data: industry, isLoading } = useHandleGetApiQuery({
    url: "user/allIndustry",
  });
  const { data: skills, isLoading: loadingSkill } = useHandleGetApiQuery({
    url: "user/allSkills",
  });
  const initialValues = {
    search: "",
    skill_id: "",
    industry_id: "",
    page: "",
  };
  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    onSubmit: async (values) => {
      // setSubmitting(true);
      const newVal = getNewProperties(initialValues, { ...values, page: 1 });
      onClose();
      navigate("/jobs", newVal);
    }
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => setFeildBySearchParams(formik, getParams), []);

  return (
    <JobFilterFormUi className="d-flex flex-column">
      <div className="row mx-0 g-2">
        <div className="col-12">
          <InputField label="Designation" id="search" name="search" type="search" value={formik.values.search}
            placeholder="ex. Graphic Design" onChange={formik.handleChange} />
        </div>
        <div className="col-12">
          <AutocompleteSingleSelect
            label="Industry Type"
            id="industry_id"
            idKey="industry_id"
            labelKey="industry_name"
            placeholder="Select Industry Type"
            noOptionsText="No industry available"
            options={industry?.data}
            isLoading={isLoading}
            value={formik.values.industry_id}
            setValue={(value) => formik.setFieldValue("industry_id", value)}
          />
        </div>
        <div className="col-12">
          <AutocompleteSingleSelect
            label="Skill"
            id="skill_id"
            idKey="skill_id"
            labelKey="skill_name"
            placeholder="Select Skill"
            noOptionsText="No industry available"
            options={skills?.data}
            isLoading={loadingSkill}
            value={formik.values.skill_id}
            setValue={(value) => formik.setFieldValue("skill_id", value)}
          />
        </div>
        <div className="col-12 bg-light pb-2 pt-3">
          <Grid container alignItems="center" spacing={2}>
            <Grid item xs={6}>
              <BtnLight type="reset" $sm className="text-text w-100" onClick={(e) => {
                formik.handleReset(e);
                navigate(pathname);
              }}>Reset</BtnLight>
            </Grid>
            <Grid item xs={6}>
              <BtnPrimary $sm type="submit" className="w-100" onClick={formik.handleSubmit}
                disabled={!formik.dirty}>Apply</BtnPrimary>
            </Grid>
          </Grid>
        </div>
      </div>

    </JobFilterFormUi>
  )

};

const FilterValueLabel = ({ label, deleteKey }) => {
  const navigate = useNavigateSearch();
  const getParams = useGetSearchQueryParams();
  
  const handleDelete = useCallback(() => {
    const newParems = { ...getParams };
    delete newParems[deleteKey];
    delete getParams[deleteKey];
    console.log(getParams);
    navigate("/jobs", newParems);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getParams])
  return (
    <Box className="d-flex align-items-center gap-2 rounded-pill" fontSize={14} lineHeight={0}
      bgcolor="#3b82f622" color="#3b82f6" pl={1} py={0.2} sx={{
        "& .svg": {
          cursor: "pointer",
          p: "1px",
          borderRadius: "50%",
          "&:hover": {
            color: "#000",
            bgcolor: "#ff000022"
          }
        }
      }}>
      {label}
      <div className="svg" onClick={handleDelete}>
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
          <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708" />
        </svg>
      </div>

    </Box>
  )
}

const JobFilters = () => {
  const [open, setOpen] = useState(false);
  const { search, skill_id, industry_id } = useGetSearchQueryParams();
  const { data: industry, isLoading } = useHandleGetApiQuery({
    url: "user/allIndustry",
  });
  const { data: skills, isLoading: loadingSkill } = useHandleGetApiQuery({
    url: "user/allSkills",
  });

  const skillName = !loadingSkill && gatTagName(skills?.data, "skill_id", skill_id, "skill_name");
  const industryName = !isLoading && gatTagName(industry?.data, "industry_id", industry_id, "industry_name");

  return (
    <div className="container">
      <div className="d-flex gap-3 align-items-start">
        <Button sx={{ textTransform: "capitalize" }} onClick={() => setOpen(true)} color="primary" startIcon={
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
            <path d="M1.5 1.5A.5.5 0 0 1 2 1h12a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.128.334L10 8.692V13.5a.5.5 0 0 1-.342.474l-3 1A.5.5 0 0 1 6 14.5V8.692L1.628 3.834A.5.5 0 0 1 1.5 3.5z" />
          </svg>
        }>Filter</Button>
        <div className="flex-grow-1 pt-2">
          <div className="d-flex flex-wrap gap-2">
            {search && (<FilterValueLabel label={search} deleteKey="search" />)}
            {skillName && (<FilterValueLabel label={skillName} deleteKey="skill_id" />)}
            {industryName && (<FilterValueLabel label={industryName} deleteKey="industry_id" />)}
          </div>
        </div>
      </div>
      <JobFilterDrawerUi
        anchor="left"
        open={open}
        onClose={() => setOpen(false)}
      >
        <div className="d-flex justify-content-between align-items-center w-100 bg-light py-3">
          <h5 className="mb-0 fw-bold">Job Filters</h5>
          <Box onClick={() => setOpen(false)} width={30} height={30} className="d-flex align-items-center justify-content-center rounded-circle bg-primary" sx={{ cursor: "pointer" }}>
            <svg xmlns="http://www.w3.org/2000/svg" height="1.2em" fill="#fff" viewBox="0 0 384 512">
              <path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" />
            </svg>
          </Box>
        </div>
        <JobFilterForm onClose={() => setOpen(false)} />
      </JobFilterDrawerUi>
    </div>
  )
}

export default JobFilters;