import { forwardRef, useEffect, useRef } from "react";
import { isValidPhoneNumber, parsePhoneNumber } from 'react-phone-number-input';
import 'react-phone-number-input/style.css'
import { InputBox, PhoneNumber, SelectInput } from "./style";
import { RadioGroup, FormControl, MenuItem, FormLabel, FormControlLabel, Radio } from "@mui/material";

export const PhoneNumberInput = ({ number, setNumber, setCountryCode, setPhone, gray }) => {
  const phoneNumber = parsePhoneNumber(`${number}`);
  const countryCode = phoneNumber?.countryCallingCode;
  const formattedPhoneNumber = phoneNumber?.nationalNumber;
  useEffect(() => {
    if (isValidPhoneNumber(`${number}`)) {
      setCountryCode(`+${countryCode}`);
      setPhone(formattedPhoneNumber);
    } else {
      setPhone("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [number])
  return (
    <div className="d-flex flex-column align-items-start gap-1">
      <FormLabel htmlFor="phone" className="text-primary fw-semibold" required>Phone Number</FormLabel>
      <PhoneNumber
        className="flex-grow-1 w-100"
        $gray={gray}
        international
        id="phone"
        defaultCountry="US"
        value={number}
        onChange={setNumber}
        placeholder="+12 7586 854 458"
      />
    </div>
  )
};

export const InputField = forwardRef(({ id, label, type, gray, ...rest }, ref) => {
  return (
    <InputBox className="d-flex flex-column align-items-start gap-1" $gray={gray}>
      <FormLabel htmlFor={id} className="text-primary fw-semibold" required={Boolean(rest.required)}>{label}</FormLabel>
      <input id={id} ref={ref} className="w-100" type={type} {...rest} autoComplete="off" />
    </InputBox>
  )
});

export const RadioInputBox = ({ id, label, valueKey, labelKey, optionList = [], ...rest }) => {
  return (
    <div className="d-flex flex-column align-items-start gap-1">
      <FormLabel htmlFor={id} className="text-primary fw-semibold" required={Boolean(rest.required)}>{label}</FormLabel>
      <FormControl>
        <RadioGroup
          row
          aria-labelledby={id}
          {...rest}
        >
          {optionList.map((option, index) => {
            const optionId = `option_${id}_${index}`;
            const optionLabel = labelKey ? option[labelKey] : option;
            const optionValue = valueKey ? option[valueKey] : option;
            return (
              <FormControlLabel key={optionId} value={optionValue} control={<Radio />} label={optionLabel} />
            )
          })}
        </RadioGroup>
      </FormControl>
    </div>
  )
}

export const FileUploadField = ({ id, fileValue, label, getFile, gray, ...rest }) => {
  const fileInputRef = useRef(null)
  const handleFile = (e) => {
    getFile(e.target.files[0]);
  };
  useEffect(() => {
    if (!fileValue) {
      fileInputRef.current.value = "";
    }
  }, [fileValue]);
  return (
    <InputBox className="d-flex flex-column align-items-start gap-1" $gray={gray}>
      <FormLabel htmlFor={id} className="text-primary fw-semibold" required={Boolean(rest.required)}>{label}</FormLabel>
      <input id={id} ref={fileInputRef} onChange={handleFile} className="w-100" type="file" {...rest} autoComplete="off" />
    </InputBox>
  )
};

export const SelectField = forwardRef(({ id, label, placeholder, valueKey, labelKey, gray, optionList = [], ...rest }, ref) => {
  return (
    <div className="d-flex flex-column align-items-start gap-1">
      <FormLabel htmlFor={id} className="text-primary fw-semibold" required={Boolean(rest.required)}>{label}</FormLabel>
      <div className="w-100">
        <SelectInput id={id} name={id} variant="outlined"
          $isEmpty={rest.value === ""} displayEmpty fullWidth inputRef={ref} {...rest}>
          <MenuItem value="">{placeholder}</MenuItem>
          {optionList.map((option, index) => {
            const optionId = `option_${id}_${index}`;
            const optionLabel = labelKey ? option[labelKey] : option;
            const optionValue = valueKey ? option[valueKey] : option;
            return (
              <MenuItem key={optionId} value={optionValue}>{optionLabel}</MenuItem>
            )
          })}
        </SelectInput>
      </div>
    </div>
  )
});

export const TextareaField = forwardRef(({ id, label, gray, ...rest }, ref) => {
  return (
    <InputBox className="d-flex flex-column align-items-start gap-1" $gray={gray}>
      <FormLabel htmlFor={id} className="text-primary fw-semibold" required={Boolean(rest.required)}>{label}</FormLabel>
      <textarea id={id} ref={ref} className="w-100" {...rest} autoComplete="off" />
    </InputBox>
  )
});

