import { Link } from "react-router-dom";
import { FoooterSection } from "./style";
import Picture from "../../components/picture";

import logo from "../../assets/images/StaffWay-logo.png";
import logo_webp from "../../assets/images/StaffWay-logo.webp";
import SocialLinks from "../../components/social-links";
import { contactMeta } from "../../helper/data";

const Footer = () => {
  return (
    <FoooterSection>
      <div className="container">
        <div className="row mx-0">
          <div className="col-12 col-xl-4">
            <div className="d-flex flex-column align-items-start align-items-md-center align-items-xl-start gap-4 pt-xl-3">
              <Link className="logo" to="/">
                <Picture src={{ webp: logo_webp, png: logo }} alt="StaffWay" width="190" loading="lazy" />
              </Link>
              <p className="text-text text-start text-md-center text-xl-start pe-0 pe-sm-5 pe-md-0 pe-xl-5 pb-3 pb-md-4 pb-xl-0" style={{ maxWidth: "590px" }}>
                At StaffWay, we are dedicated to empowering your workforce journey by connecting talent with opportunity, fostering growth, and building success stories.
              </p>
            </div>
          </div>
          <div className="col-12 col-xl-8">
            <div className="d-flex justify-content-start justify-content-sm-around justify-content-lg-between flex-wrap">
              <div className="footer-column">
                <div className="d-flex flex-column gap-2 pb-4">
                  <h6 className="text-primary">Quick Links</h6>
                  <Link className="nav-item" to="/">Home</Link>
                  <Link className="nav-item" to="/jobs">Jobs</Link>
                  <Link className="nav-item" to="/about-us">About Us</Link>
                  <Link className="nav-item" to="/contact-us">Contact Us</Link>
                </div>
              </div>
              <div className="footer-column">
                <div className="d-flex flex-column gap-2 pb-4">
                  <h6 className="text-primary">Company</h6>
                  <Link className="nav-item" to="/privacy-policy">Privacy Policy</Link>
                  <Link className="nav-item" to="/terms-and-conditions">Terms & Conditions</Link>
                  <Link className="nav-item" to="/faqs">FAQs</Link>
                </div>
              </div>
              <div className="footer-column">
                <div className="d-flex flex-column gap-2 pb-4">
                  <h6 className="text-primary">Contact</h6>
                  <Link className="nav-item" to={`tel:${contactMeta.number}`}>{contactMeta.number}</Link>
                  <Link className="nav-item" to={`mailto:${contactMeta.email}`}>{contactMeta.email}</Link>
                </div>
              </div>
              <div className="footer-column">
                <div className="d-flex flex-column gap-2 pb-4">
                  <h6 className="text-primary">Offices</h6>
                  <span className="nav-item text-text">104 W 40th St, Suite 500, <br />New York, NY 10018, US</span>
                  <span className="nav-item text-text pb-3">A3, Block A, Sector 4, Noida, <br />Delhi 110092, IN</span>
                  <SocialLinks />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="spacer py-3 mb-3">
          <div />
        </div>
        <p className="mb-0 text-text copyright">&copy;StaffWay, All rights reserved, Product by Web Alpha</p>
      </div>
    </FoooterSection>
  )
}

export default Footer;