import React from 'react';

const useMediaQuery = (mediaQuery) => {
  const query = mediaQuery.replace(/^@media( ?)/m, '')
  const [matches, setMatches] = React.useState(() => window.matchMedia(query).matches);
  React.useEffect(() => {
    const matchQueryList = window.matchMedia(query);
    
    const handleChange = (e) => {
      setMatches(e.matches);
    }
    
    matchQueryList.addEventListener("change", handleChange);
    
    return () => {
      matchQueryList.removeEventListener("change", handleChange);
    };
  }, [query]);
  return matches;
}

export default useMediaQuery;