import styled from "styled-components";
import breakpoints from "../../helper/breakpoints";

export const FoooterSection = styled.footer`
padding: 5em 0 2em;
${breakpoints.down("md")} {
  padding: 4em 0 2em;
}

.footer-column {
  width: auto;
  max-width: 270px;
  ${breakpoints.down("lg")} {
    width: 40%;
    max-width: unset;
  }
  ${breakpoints.down("md")} {
    width: 50%;
  }
  ${breakpoints.down("sm")} {
    width: 100%;
  }
  h6 {
    font-size: 20px;
    font-weight: 600;
    ${breakpoints.down("md")} {
      font-size: 18px;
    }
  }
  .nav-item {
    font-size: 17px;
    color: var(--bs-text);
    text-decoration: none !important;
    transition: all .3s;
    &:hover{
      color: var(--bs-accent)
    }
    ${breakpoints.down("md")} {
      font-size: 16px;
    }
  }
}

.spacer > div {
  border-top: 1px solid #23145917;
}
.copyright {
  font-size: 12px;
}
`;