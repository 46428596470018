import styled from "styled-components";
import bg_cta from "../../assets/images/bg-cta-2.png";
import breakpoints from "../../helper/breakpoints";

export const PrimaryOverlaySection = styled.section`
  background-color: var(--bs-primary);
  position: relative;
  .overlay {
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    position: absolute;
    background-image: url(${bg_cta});
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    opacity: 0.7;
    z-index: 0;
    pointer-events: none;
    user-select: none;
  }
  &>.d-flex, &>.row {
    position: relative;
    z-index: 1;
  }
`;

export const PrimaryHeaderSection = styled.header`
background-color: transparent;
background-image: linear-gradient(180deg, var(--bs-secondary) 50%, transparent 100%);
padding-top: 8em;
${breakpoints.down("xl")} {
  padding-top: 5em;
}
${breakpoints.down("md")} {
  padding-top: 4em;
}
`;