import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import AWS from "aws-sdk";
import moment from "moment";

export const ResetScroll = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return null;
};

export const appendZero = (num) => {
  if (isNaN(+num)) {
    return "";
  } else {
    const number = num < 10 ? `0${num}` : num;
    return number;
  }
}
export const convertToICS = (labelValue) => {
  let val;
  switch (true) {
    // 15 Zeroes for Quadrillions
    case Math.abs(Number(labelValue)) >= 1.0e15:
      val = `${(Math.abs(Number(labelValue)) / 1.0e15).toFixed(2)}Q`;
      break;
    // 12 Zeroes for Trillions
    case Math.abs(Number(labelValue)) >= 1.0e12:
      val = `${(Math.abs(Number(labelValue)) / 1.0e12).toFixed(2)}T`;
      break;
    // Nine Zeroes for Billions
    case Math.abs(Number(labelValue)) >= 1.0e9:
      val = `${(Math.abs(Number(labelValue)) / 1.0e9).toFixed(2)}B`;
      break;
    // Six Zeroes for Millions
    case Math.abs(Number(labelValue)) >= 1.0e6:
      val = `${(Math.abs(Number(labelValue)) / 1.0e6).toFixed(2)}M`;
      break;
    // Nine Zeroes for Billions
    case Math.abs(Number(labelValue)) >= 1.0e3:
      val = `${(Math.abs(Number(labelValue)) / 1.0e3).toFixed(2)}K`;
      break;
    default:
      val = labelValue;
      break;
  }
  return val;
};

export const displayLabel = (apiArray, displayArray) => {
  const selectedJobTypeIds = apiArray.split(",").map(Number);

  const selectedJobTypes = displayArray
    .filter((jobType) => selectedJobTypeIds.includes(jobType.id))
    .map((jobType) => jobType.name);

  return selectedJobTypes.join(", ");
};

export const uploadFileToS3 = async (file, type) => {
  const s3 = new AWS.S3({
    accessKeyId: process.env.REACT_APP_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY,
    // region: process.env.REACT_APP_S3_REGION,
  });
  const fileName = type + moment(new Date()).format("DDDD_MM_YYYY_hh_mm_a_x") + file.name;
  const params = {
    Bucket: process.env.REACT_APP_BUCKET_NAME,
    Key: fileName.toLowerCase(),
    Body: file,
    ContentType: file.type,
    ACL: "public-read",
  };
  return [params, s3];
};

export const getNewProperties = (prevObj, newObj) => {
  const resultObject = {};
  for (const key in prevObj) {
    if (prevObj[key] !== newObj[key]) {
      if (
        newObj[key]?.length > 0 ||
        !isNaN(newObj[key]) ||
        (newObj[key] && typeof newObj[key] === "object")
      ) {
        resultObject[key] = newObj[key];
      } else {
        resultObject[key] = null;
      }
    }
  }
  return resultObject;
};

export const setFeildBySearchParams = (formik, getSearchParams) => {
  for (const key in getSearchParams) {
    const value = getSearchParams[key];
    formik.setFieldValue(key, isNaN(+value) ? value : +value);
  }
};

export const gatTagName = (data, key, id, lableKey) => {
  if (!id && isNaN(+id) && !data) {
    return null;
  }
  const dataArray = data?.filter((item) => item[key] === id);
  return dataArray?.length === 0 ? null : dataArray[0][lableKey];
}