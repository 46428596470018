export const navLinks = [
  {
    id: "home",
    link: "/",
    name: "Home"
  },
  {
    id: "jobs",
    link: "/jobs",
    name: "Jobs"
  },
  {
    id: "about-us",
    link: "/about-us",
    name: "About Us"
  },
  {
    id: "contact-us",
    link: "/contact-us",
    name: "Contact Us"
  }
]