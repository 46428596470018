import styled from "styled-components";
import breakpoints from "../../helper/breakpoints";
import { Link } from "react-router-dom";

export const BtnPrimary = styled.button`
font-size: 1.125rem;
font-weight: 600;
color: var(--bs-light);
background-color: transparent;
background-image: linear-gradient(145deg, var(--bs-accent) 0%, var(--bs-info) 100%);
border-style: none;
border-radius: 12px 12px 12px 12px;
padding: 0.8em 2em 0.8em 2em;
text-decoration: none;
text-align: center;
transition: all .3s;

${({ $sm }) => $sm && `
font-size: 1rem;
padding: 0.7em 1.5em 0.7em 1.5em;
`}

${breakpoints.down("lg")} {
  font-size: 1rem;
}

&:focus, &:hover {
  box-shadow: 0px 0px 0px 8px rgba(125.00000000000018, 93, 244.00000000000003, 0.24);
}
&:disabled {
  opacity: 0.7;
  box-shadow: none;
}
`;

export const BtnSecondary = styled.button`
font-size: 1.125rem;
font-weight: 600;
color: var(--bs-primary);
background-color: #23145917;
border-style: none;
border-radius: 12px 12px 12px 12px;
padding: 0.8em 2em 0.8em 2em;
text-decoration: none;
text-align: center;
transition: all .3s;

${({ $sm }) => $sm && `
font-size: 1rem;
padding: 0.7em 1.5em 0.7em 1.5em;
`}

${breakpoints.down("lg")} {
  font-size: 1rem;
}

&:focus, &:hover {
  color: var(--bs-light);
  background-color: var(--bs-primary);
}
`;
export const BtnLight = styled.button`
font-size: 1.125rem;
font-weight: 600;
color: var(--bs-primary);
background-color: transparent;
background-image: linear-gradient(180deg, var(--bs-light) 0%, var(--bs-light) 100%);
border-style: none;
border-radius: 12px 12px 12px 12px;
padding: 0.8em 2em 0.8em 2em;
text-decoration: none;
text-align: center;
transition: all .3s;

${({ $sm }) => $sm && `
font-size: 1rem;
padding: 0.7em 1.5em 0.7em 1.5em;
`}

${breakpoints.down("lg")} {
  font-size: 1rem;
}

&:focus, &:hover {
  color: var(--bs-light);
  background-color: transparent;
  background-image: linear-gradient(180deg, #FFFFFF1C 0%, #FFFFFF1C 100%);
}
}
`;

export const LinkPrimary = styled(Link)`
font-size: 1.125rem;
font-weight: 600;
color: var(--bs-light) !important;
background-color: transparent;
background-image: linear-gradient(145deg, var(--bs-accent) 0%, var(--bs-info) 100%);
border-style: none;
border-radius: 12px 12px 12px 12px;
padding: 0.8em 2em 0.8em 2em;
text-decoration: none !important;
text-align: center;
transition: all .3s;

${({ $sm }) => $sm && `
font-size: 1rem;
padding: 0.7em 1.5em 0.7em 1.5em;
`}

${breakpoints.down("lg")} {
  font-size: 1rem;
}

&:focus, &:hover {
  box-shadow: 0px 0px 0px 8px rgba(125.00000000000018, 93, 244.00000000000003, 0.24);
}
`;

export const LinkSecondary = styled(Link)`
font-size: 1.125rem;
font-weight: 600;
color: var(--bs-primary);
background-color: #23145917;
border-style: none;
border-radius: 12px 12px 12px 12px;
padding: 0.8em 2em 0.8em 2em;
text-decoration: none !important;
text-align: center;
transition: all .3s;
&>svg {
  transition: all .3s;
  fill: var(--bs-primary);
}
${({ $sm }) => $sm && `
font-size: 1rem;
padding: 0.7em 1.5em 0.7em 1.5em;
`}

${breakpoints.down("lg")} {
  font-size: 1rem;
}

&:focus, &:hover {
  color: var(--bs-light);
  background-color: var(--bs-primary);
  &>svg {
    fill: var(--bs-light);
  }
}
`;

export const LinkLight = styled(Link)`
font-size: 1.125rem;
font-weight: 600;
color: var(--bs-primary);
background-color: transparent;
background-image: linear-gradient(180deg, var(--bs-light) 0%, var(--bs-light) 100%);
border-style: none;
text-decoration: none !important;
border-radius: 12px 12px 12px 12px;
padding: 0.8em 2em 0.8em 2em;
text-decoration: none;
text-align: center;
transition: all .3s;

${({ $sm }) => $sm && `
font-size: 1rem;
padding: 0.7em 1.5em 0.7em 1.5em;
`}

${breakpoints.down("lg")} {
  font-size: 1rem;
}

&:focus, &:hover {
  color: var(--bs-light);
  background-color: transparent;
  background-image: linear-gradient(180deg, #FFFFFF1C 0%, #FFFFFF1C 100%);
}
}
`;

export const ReadMore = styled(Link)`
text-decoration: none !important;
font-weight: 500;
font-size: 16px;
color: ${({ $light }) => $light ? "var(--bs-primary)" : "var(--bs-light)"};
transition: all .3s;
&:hover{
  color: var(--bs-accent);
}

${breakpoints.down("md")} {
  font-size: 14px;
}
`