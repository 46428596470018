import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/dist/query';
import cartReducer from './features/cartSlice';
import { baseAPIService } from './features/apis';

const store = configureStore({
  reducer: {
    cart: cartReducer,
    [baseAPIService.reducerPath]: baseAPIService.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).concat(baseAPIService.middleware),
  devTools: true,
});

setupListeners(store.dispatch);

export default store;
