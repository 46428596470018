/* eslint-disable jsx-a11y/alt-text */

const Picture = ({ src, customClass, children, ...rest }) => {
  const { webp, png, jpg, jpeg } = src;
  return (
    <picture>
      {children}
      <source type="image/webp" srcSet={webp} />
      {png && <source type="image/png" srcSet={png} />}
      {jpg && <source type="image/jpg" srcSet={jpg} />}
      {jpeg && <source type="image/jpeg" srcSet={jpeg} />}
      <img className={`img-fluid ${customClass || ""}`} src={png || jpg || jpeg} {...rest} />
    </picture>
  )
}

export default Picture;