import ReactDOM from 'react-dom/client';
import App from './App';
import './index.css';
import 'react-loading-skeleton/dist/skeleton.css';
import { Provider } from 'react-redux';
import store from './app/store';
import { SnackbarProvider } from 'notistack';

ReactDOM.createRoot(document.getElementById('root')).render(
  <Provider store={store}>
    <SnackbarProvider
      maxSnack={4}
      autoHideDuration={3000}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      preventDuplicate
    >
      <App />
    </SnackbarProvider>
  </Provider>
  ,
);
