import { useSearchParams } from "react-router-dom";

const useGetSearchQueryParams = () => {
  const [searchParams] = useSearchParams();
  let allSearchParams = {};
  for (const entry of searchParams.entries()) {
    const [param, value] = entry;
    allSearchParams[param] = isNaN(+value) ? value : +value;
  }
  return allSearchParams;
};
export default useGetSearchQueryParams;