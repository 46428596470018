import { Suspense } from "react";
import { Outlet } from "react-router-dom";
import Preloader from "../common/preloader";
import Navbar from "../common/navbar";
import Footer from "../common/footer";
import { ResetScroll } from "../helper";

const RootLayout = () => {
  return (
    <>
      <Suspense fallback={<Preloader />}>
        <Navbar />
        <ResetScroll />
        <Outlet />
        <Footer />
      </Suspense>
    </>
  )
}

export default RootLayout;