export const jobMode = [
  {
    id: 1,
    name: "On-Site",
  },
  {
    id: 2,
    name: "Hybrid",
  },
  {
    id: 3,
    name: "Remote",
  },
];
export const jobType = [
  {
    id: 1,
    name: "Full-Time",
  },
  {
    id: 2,
    name: "Part-Time",
  },
  {
    id: 3,
    name: "Contractual",
  },
];

export const contactMeta = {
  number: "+1-289-724-9710",
  email: "info@staffway.io",
}

export const yesOrNo = [
  {
    value: 1,
    label: "Yes"
  },
  {
    value: 0,
    label: "No"
  }
];

export const preferredLanguages = [
  {
    value: "english",
    label: "English"
  },
  {
    value: "french",
    label: "French"
  }
];

export const provinceData = [
  { id: 1, value: "Alabama", },
  { id: 2, value: "Alaska", },
  { id: 3, value: "Alberta", },
  { id: 4, value: "Arizona", },
  { id: 5, value: "Arkansas", },
  { id: 6, value: "British Columbia", },
  { id: 7, value: "California", },
  { id: 8, value: "Colorado", },
  { id: 9, value: "Connecticut", },
  { id: 10, value: "Delaware", },
  { id: 11, value: "Florida", },
  { id: 12, value: "Georgia", },
  { id: 13, value: "Hawaii", },
  { id: 14, value: "Idaho", },
  { id: 15, value: "Illinois", },
  { id: 16, value: "Indiana", },
  { id: 17, value: "Iowa", },
  { id: 18, value: "Kansas", },
  { id: 19, value: "Kentucky", },
  { id: 20, value: "Louisiana", },
  { id: 21, value: "Maine", },
  { id: 22, value: "Manitoba", },
  { id: 23, value: "Maryland", },
  { id: 24, value: "Massachusetts", },
  { id: 25, value: "Michigan", },
  { id: 26, value: "Minnesota", },
  { id: 27, value: "Mississippi", },
  { id: 28, value: "Missouri", },
  { id: 29, value: "Montana", },
  { id: 30, value: "Nebraska", },
  { id: 31, value: "Nevada", },
  { id: 32, value: "New Brunswick", },
  { id: 33, value: "New Hampshire", },
  { id: 34, value: "New Jersey", },
  { id: 35, value: "New Mexico", },
  { id: 36, value: "New York", },
  { id: 37, value: "Newfoundland and Labrador", },
  { id: 38, value: "North Carolina", },
  { id: 39, value: "North Dakota", },
  { id: 40, value: "Northwest Territories", },
  { id: 41, value: "Nova Scotia", },
  { id: 42, value: "Nunavut", },
  { id: 43, value: "Ohio", },
  { id: 44, value: "Oklahoma", },
  { id: 45, value: "Ontario", },
  { id: 46, value: "Oregon", },
  { id: 47, value: "Pennsylvania", },
  { id: 48, value: "Prince Edward Island", },
  { id: 49, value: "Quebec", },
  { id: 50, value: "Rhode Island", },
  { id: 51, value: "Saskatchewan", },
  { id: 52, value: "South Carolina", },
  { id: 53, value: "South Dakota", },
  { id: 54, value: "Tennessee", },
  { id: 55, value: "Texas", },
  { id: 56, value: "Utah", },
  { id: 57, value: "Vermont", },
  { id: 58, value: "Virginia", },
  { id: 59, value: "Washington", },
  { id: 60, value: "West Virginia", },
  { id: 61, value: "Wisconsin", },
  { id: 62, value: "Wyoming", },
  { id: 63, value: "Yukon" },
];
