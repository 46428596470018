import Footer from "../../common/footer";
import Navbar from "../../common/navbar";
import notFoundImage from '../../assets/images/404-image.png'
import { Box } from "@mui/material";
import { H3Title } from "../home/components/style";
import { LinkPrimary } from "../../components/button";

const NotFound = () => {
  return (
    <>
      <Navbar />
      <Box sx={{ backgroundImage: "linear-gradient(180deg, #ecf5f7 50%, #fff0 100%)" }} py={7}>
        <div className="container">
          <div className="d-flex flex-column gap-3 align-items-center">
            <img className="img-fluid" src={notFoundImage} alt="404" width={486} />
            <H3Title className="text-primary mb-0 mt-2">Page not found</H3Title>
            <p className="text-text text-center">Oops. The page you were looking for doesn’t exist!</p>
            <LinkPrimary to="/">Back to Home</LinkPrimary>
          </div>
        </div>
      </Box>
      <Footer />
    </>
  )
}

export default NotFound;