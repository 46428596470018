import styled from "styled-components";
import { PrimaryHeaderSection } from "../../../common/primery-overlay";
import breakpoints from "../../../helper/breakpoints";
import ReactPaginate from "react-paginate";
import { Drawer } from "@material-ui/core";

export const HeaderSectionJobList = styled(PrimaryHeaderSection)`
padding-bottom: 4em;

${breakpoints.down("xl")} {
  padding-bottom: 3em;
}
${breakpoints.down("md")} {
  padding-bottom: 2em;
}
`;

export const JobListingSection = styled.section``;

export const JobFilterDrawerUi = styled(Drawer)`
.MuiPaper-root {
  max-width: 320px;
  width: 100%;
  padding: 0 1rem;
}
`;

export const JobFilterFormUi = styled.form`

`;

export const PaginationUI = styled(ReactPaginate)`
list-style: none;
column-gap: 10px;
padding: 10px 0;
.disabled {
  opacity: 0.5;
}
.page-link {
  text-decoration: none;
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}
.link:hover {
  background-color: rgba(0, 0, 0, 0.04);
}
.active .page-link {
  color: var(--bs-light);
  background-color: var(--bs-primary);
}
`;

