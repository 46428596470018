import { Select } from "@material-ui/core";
import PhoneInput from "react-phone-number-input";
import styled from "styled-components";

export const InputBox = styled.div`
input {
  background-color: ${({ $gray }) => $gray ? "#2314590A" : "var(--bs-light)"};
  padding: 14px 20px;
  border: 1px solid #23145917;
  border-radius: 12px;
  color: #1f2124;
  min-height: 59px;
  outline: none;
  &:focus {
    border-color: #69727d;
  }
  &:-webkit-autofill,
  &:-webkit-autofill:hover, 
  &:-webkit-autofill:focus, 
  &:-webkit-autofill:active{
      -webkit-box-shadow: 0 0 0 30px  ${({ $gray }) => $gray ? "#2314590A" : "var(--bs-light)"} inset !important;
  }
}
textarea {
  background-color: ${({ $gray }) => $gray ? "#2314590A" : "var(--bs-light)"};
  padding: 7px 20px;
  border: 1px solid #23145917;
  border-radius: 12px;
  color: #1f2124;
  outline: none;
  &:focus {
    border-color: #69727d;
  }
}
`;

export const PhoneNumber = styled(PhoneInput)`
  background-color: ${({ $gray }) => $gray ? "#2314590A" : "var(--bs-light)"};
  padding: 7px 20px;
  border: 2px solid #23145917;
  border-radius: 12px;
  color: #1f2124;
  min-height: 59px;
  .PhoneInputInput {
    background: transparent;
  }
  &:focus-within {
    border-color: #69727d;
  }
  & input {
    border: none !important;
    outline: none !important;
    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active{
        -webkit-box-shadow: 0 0 0 30px  ${({ $gray }) => $gray ? "#2314590A" : "var(--bs-light)"} inset !important;
    }
  }
`
export const SelectInput = styled(Select)`
&.MuiInputBase-root {
  font-family: "Sora", sans-serif;
  .MuiSelect-root{
    color: ${({ $isEmpty }) => $isEmpty ? "#23145977" : "#1f2124"};
    border-radius: 12px !important;
    background-color: ${({ $gray }) => $gray ? "#2314590A" : "var(--bs-light)"} !important;
  }
  fieldset.MuiOutlinedInput-notchedOutline {
    border: 2px solid #23145917;
    border-radius: 12px;
    color: #1f2124;
    top: 0;
  }
  &.Mui-focused {
    fieldset.MuiOutlinedInput-notchedOutline {
      border-color: #69727d;
    }
  }
}
`;
