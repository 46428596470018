import { lazy } from "react";

import RootLayout from "./layout/RootLayout";
import NotFound from "./layout/not-found/NotFound";

const Home = lazy(() => import("./layout/home/Home"));
const JobList = lazy(() => import("./layout/job-list/JobList"));
const JobDetailed = lazy(() => import("./layout/job-detailed/JobDetailed"));
const AboutUs = lazy(() => import("./layout/about-us/AboutUs"));
const ContactUs = lazy(() => import("./layout/contact-us/ContactUs"));
const FAQs = lazy(() => import("./layout/faqs/FAQs"));
const PrivacyPolicy = lazy(() => import("./layout/privacy-policy/PrivacyPolicy"));
const TermsConditions = lazy(() => import("./layout/terms-and-conditions/TermsConditions"));
const Test = lazy(() => import("./layout/Test"));

const routes = [
  {
    path: "/",
    element: <RootLayout />,
    errorElement: <NotFound />,
    children: [
      {
        index: true,
        element: <Home />
      },
      {
        path: 'jobs',
        children: [
          {
            index: true,
            element: <JobList />,
          },
          {
            path: ':id',
            element: <JobDetailed />
          }
        ]
      },
      {
        path: 'about-us',
        element: <AboutUs />
      },
      {
        path: 'test',
        element: <Test />
      },
      {
        path: 'contact-us',
        element: <ContactUs />
      },
      {
        path: 'faqs',
        element: <FAQs />
      },
      {
        path: 'privacy-policy',
        element: <PrivacyPolicy />
      },
      {
        path: 'terms-and-conditions',
        element: <TermsConditions />
      },
    ],
  },
];

export default routes;
