import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  miniSidenav: false,
  transparentSidenav: true,
  sidenavColor: "warning",
  transparentNavbar: true,
  fixedNavbar: false,
  openConfigurator: false,
  direction: "ltr",
  layout: "dashboard",
};

export const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    updateUI: (state, { payload }) => {
      return {
        ...state,
        [payload.type]: payload.value,
      };
    },
  },
});

export const { updateUI } = cartSlice.actions;

export default cartSlice.reducer;
