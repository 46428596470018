import { useEffect } from "react";

const Preloader = () => {
  useEffect(() => {
    document.documentElement.style.overflowY = "hidden";
    document.body.style.overflowY = "hidden";
    return () => {
      document.documentElement.removeAttribute("style");
      document.body.removeAttribute("style");
    }
  }, []);
  return (
    <div className="preloader-container">
      <div className="spinner">
        {Array(5).fill("").map((_, i) => <div key={i} className={`rect${i + 1}`} />)}
      </div>
    </div>
  )
}

export default Preloader;