import styled, { keyframes } from "styled-components";
import breakpoints from "../../helper/breakpoints";

export const MainNav = styled.div`
padding: 1.2rem 0;
& > .logo, & > .search-job {
  width: 25%;
}
& > .logo {
  ${breakpoints.down("lg")} {
    width: 26%;
  }
  ${breakpoints.down("sm")} {
    width: 50%;
  }
}
 & > .search-job {
  ${breakpoints.down("lg")} {
    width: 64%;
  }
}
`;

export const DeskNav = styled.nav`
column-gap: 20px;
.nav-item {
  position: relative;
  margin: 0 10px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  &:nth-first-child {
    margin-left: 0;
  }
  &:nth-last-child {
    margin-right: 0;
  }

  .nav-link {
    padding: 13px 0;
    color: var(--bs-primary);
    text-decoration: none !important;
    font-size: 1rem;
    transition: .4s;

    &::after {
      content: "";
      bottom: 0;
      display: block;
      position: absolute;
      transition: .3s;
      transition-timing-function: cubic-bezier(.58,.3,.005,1);
      height: 0;
      width: 0;
      left: 50%;
      background-color: var(--bs-accent);
      opacity: 0;
      transform: scale(0);
    }

    &:hover, 
    &.current {
      &::after {
        left: 0;
        width: 100%;
        height: 1.5px;
        opacity: 1;
        transform: scale(1);
      }
    }
  }
}
`;

export const ToggleBtn = styled.div`
cursor: pointer;
padding: 7px;
color: var(--bs-primary);
background-color: #2314590F;
border-radius: 8px;
`;

const hide_scroll = keyframes`
0%, 100% {
  overflow: hidden;
}
`;

export const MobileNav = styled.nav`
box-shadow: 0px 0px 20px 0px rgba(99.99999999999997, 70, 214, 0.1);
transition: max-height .3s,transform .3s;
transform-origin: top;
overflow-y: auto;
overflow-x: hidden;
background-color: var(--bs-light);
border-style: solid;
border-width: 16px 4px 16px 4px;
border-color: var(--bs-light);
border-radius: 12px 12px 12px 12px;
transform: scaleY(0);
max-height: 0;
overflow: hidden;
gap: 5px;
z-index: 1;
margin-top: -8px;
&.active {
  transform: scaleY(1);
  max-height: 1000vmax;
  animation: ${hide_scroll} .3s backwards;
}

.nav-item {
  .nav-link {
    display: block;
    padding: 8px 20px;
    color: var(--bs-primary);
    text-decoration: none !important;
    font-size: 1rem;
    transition: .4s;

    &:hover, 
    &.current {
      color: var(--bs-accent);
    }
  }
}
`;

