import { baseAPIService } from ".";

const CommonGetApi = baseAPIService.injectEndpoints({
  endpoints: (build) => ({
    handleGetApi: build.query({
      query: ({ url, params }) => {
        return {
          url: url,
          params: params,
        };
      },
      keepUnusedDataFor: 0,
      providesTags: ["COMMON_GET"],
    }),
  }),
  overrideExisting: true,
});

export const { useHandleGetApiQuery } = CommonGetApi;
