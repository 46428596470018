const breakpoints = {
  keys: ['xs', 'sm', 'md', 'lg', 'xl', 'xxl'],
  values: {
    xs: 0,
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200,
    xxl: 1400,
  },
  unit: "px",
  up(e) {
    const t = "number" == typeof this.values[e] ? this.values[e] : e;
    return "@media (min-width:".concat(t).concat(this.unit, ")")
  },
  down(e) {
    const t = "number" == typeof this.values[e] ? this.values[e] : e;
    return "@media (max-width:".concat(t - 5 / 100).concat(this.unit, ")")
  },
  between(e, t) {
    const r = this.keys.indexOf(t);
    return "@media (min-width:".concat("number" == typeof this.values[e] ? this.values[e] : e).concat(this.unit, ") and ") + "(max-width:".concat((-1 !== r && "number" == typeof this.values[this.keys[r]] ? this.values[this.keys[r]] : t) - 5 / 100).concat(this.unit, ")")
  },
  only(e) {
    const f = this.keys;
    return f.indexOf(e) + 1 < f.length ? this.between(e, f[f.indexOf(e) + 1]) : this.up(e);
  },
  not(e) {
    const f = this.keys;
    const t = f.indexOf(e);
    return 0 === t ? this.up(f[1]) : t === f.length - 1 ? this.down(f[t]) : this.between(e, f[f.indexOf(e) + 1]).replace("@media", "@media not all and")
  },
};

export default breakpoints;